import '../../App.css';

import { Radio } from 'antd';

const RadioButtonGroup = Radio.Group;

const RadioGroup = (props) => {
	const { value, handleChange } = props
	
  return (
		<div className='mb-40'>
			<RadioButtonGroup value={value} onChange={handleChange}>
				<Radio value='monthly' className='radio'>Pay monthly</Radio>
				<Radio value='quarterly' className='radio'>
					<div className='radio-content'>
						Pay quarterly
						<span className='discount'>Save 5%</span>
					</div>
				</Radio>
				<Radio value='semiAnnually' className='radio'>
					<div className='radio-content'>
						Pay semi-annually
						<span className='discount'>Save 10%</span>
					</div>
				</Radio>
				<Radio value='annually' className='radio'>
					<div className='radio-content'>
						Pay annually
						<span className='discount'>Save 15%🔥</span>
					</div>
				</Radio>
			</RadioButtonGroup>
		</div>
  );
};

export default RadioGroup;
