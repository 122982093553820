import { Button, Layout, Typography } from "antd"
import { ScheduleOutlined, SendOutlined } from "@ant-design/icons"

import { ScrollArrow } from "../../components"

const { Content } = Layout
const { Text } = Typography

const Intro = () => {
  const openUrl = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  return (
    <Content className="intro-content">
      <div>
        <h4 className="gradient-text">
          Unveiling IT Services Price Catalog: Empowering Your Tech Journey
        </h4>
        <Text className="description">
          Welcome to our IT Services Price List page! Here, you'll find a
          comprehensive range of technology solutions designed to meet your
          unique needs.
        </Text>
        <br />
        <Text className="description">
          Our transparent pricing ensures that you can easily choose the
          services that align with your requirements and budget.
        </Text>
        <br />
        <Text className="description">
          Empower your business with top-notch IT services, backed by clear and
          competitive pricing.
        </Text>
      </div>
      <div className="button-container">
        <Button
          className="br-6"
          ghost
          onClick={() =>
            openUrl("https://calendly.com/slavka1406/sheduled-call")
          }
        >
          <ScheduleOutlined />
          Schedule a call
        </Button>
        <Button
          type="primary"
          className="br-6"
          onClick={() => openUrl("https://t.me/Viacheslav_Shamreliuk")}
        >
          <SendOutlined />
          Make an order
        </Button>

        <ScrollArrow />
      </div>
    </Content>
  )
}

export default Intro
