import './App.css';

import { Intro, SoftwareDevelopment, SoftwareSupport, TLAndSD, TechnicalLead } from './sections'

import { Layout } from 'antd';

const { Content } = Layout;

function App() {
  return (
    <Layout className="layout">
			<div className='h-100vh'>
        <Intro />
			</div>
      <Content className='content'>
        <TechnicalLead />
        <SoftwareDevelopment />
        <TLAndSD />
				<SoftwareSupport />
      </Content>
    </Layout>
  );
}



export default App;
