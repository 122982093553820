import { useEffect, useState } from "react";

const useArrow = () => {
	const [isArrowHidden, setIsArrowHidden] = useState(false);
  const handleScroll = () => {
    const scroll = window.scrollY;
    setIsArrowHidden(scroll >= 1);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

	return isArrowHidden
}

export default useArrow