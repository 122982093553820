import "../../App.css"

import { Card, Divider, Layout, Typography } from "antd"
import { Fragment, useState } from "react"

import { RadioGroup } from "../../components"
import { windowScroll } from "../../helpers"

const { Title, Text } = Typography
const { Content } = Layout

const SoftwareSupport = () => {
  const pricePerHour = 28.4
  const TLpricePerHour = pricePerHour * 1.1 //
  const monthlyTechLeadPrice = TLpricePerHour * 58

  const priceLite = 32.59
  const priceNormal = 126.54
  const pricePremium = 365.25

  const techLeadPrice = {
    monthly: {
      projects: 1,
      chatSupport: 21,
      shortVideoConsultations: 0.25 * 4,
      longVideoConsultations: 1 * 4,
      codeReview: 28,
      price: monthlyTechLeadPrice,
      postpaidPrice: monthlyTechLeadPrice * 1.1,
      installment: monthlyTechLeadPrice * 1.2,
      discountPrice: null,
    },
    quarterly: {
      projects: 1,
      chatSupport: 21,
      shortVideoConsultations: 0.25 * 4,
      longVideoConsultations: 1 * 4,
      codeReview: 28,
      price: monthlyTechLeadPrice * 0.95 * 3,
      postpaidPrice: null,
      installment: null,
      discountPrice: monthlyTechLeadPrice * 0.95,
    },
    semiAnnually: {
      projects: 1,
      chatSupport: 21,
      shortVideoConsultations: 0.25 * 4,
      longVideoConsultations: 1 * 4,
      codeReview: 28,
      price: monthlyTechLeadPrice * 0.9 * 6,
      postpaidPrice: null,
      installment: null,
      discountPrice: monthlyTechLeadPrice * 0.9,
    },
    annually: {
      projects: 1,
      chatSupport: 21,
      shortVideoConsultations: 0.25 * 4,
      longVideoConsultations: 1 * 4,
      codeReview: 28,
      price: monthlyTechLeadPrice * 0.85 * 12,
      postpaidPrice: null,
      installment: null,
      discountPrice: monthlyTechLeadPrice * 0.85,
    },
  }

  // Radio buttons
  const [paymentMethod, setPaymentMethod] = useState("monthly")

  const isDiscount = techLeadPrice?.[paymentMethod]?.discountPrice

  const handleRadioChange = (e) => {
    setPaymentMethod(e.target.value) // Update the selected value when a radio button is clicked
  }

  // Window scroll
  window.addEventListener("scroll", windowScroll)
  windowScroll()

  const monthly = [
    {
      plan: "lite",
      time: 48,
      availabilityHours: "10:00-18:00",
      availabilityDays: "Mon-Fri",
      requestsNum: 4,
      price: priceLite,
      postpaidPrice: priceLite * 1.1,
      installment: (priceLite * 1.2) / 3,
      discountPrice: null,
    },
    {
      plan: "basic",
      time: 24,
      availabilityHours: "10:00-18:00",
      availabilityDays: "Mon-Fri",
      requestsNum: 10,
      price: priceNormal,
      postpaidPrice: priceNormal * 1.1,
      installment: (priceNormal * 1.2) / 3,
      discountPrice: null,
    },
    {
      plan: "premium",
      time: 4,
      availabilityHours: "10:00-22:00",
      availabilityDays: "Mon-Sun",
      requestsNum: "unlimited",
      price: pricePremium,
      postpaidPrice: pricePremium * 1.1,
      installment: (pricePremium * 1.2) / 3,
      discountPrice: null,
    },
  ]

  const quarterly = [
    {
      plan: "lite",
      time: 48,
      availabilityHours: "10:00-18:00",
      availabilityDays: "Mon-Fri",
      requestsNum: 4,
      price: priceLite * 0.95 * 3,
      postpaidPrice: null,
      installment: null,
      discountPrice: priceLite * 0.95,
      originalPrice: priceLite,
    },
    {
      plan: "basic",
      time: 24,
      availabilityHours: "10:00-18:00",
      availabilityDays: "Mon-Fri",
      requestsNum: 10,
      price: priceNormal * 0.95 * 3,
      postpaidPrice: null,
      installment: null,
      discountPrice: priceNormal * 0.95,
      originalPrice: priceNormal,
    },
    {
      plan: "premium",
      time: 4,
      availabilityHours: "10:00-22:00",
      availabilityDays: "Mon-Sun",
      requestsNum: "unlimited",
      price: pricePremium * 0.95 * 3,
      postpaidPrice: null,
      installment: null,
      discountPrice: pricePremium * 0.95,
      originalPrice: pricePremium,
    },
  ]

  const semiAnnually = [
    {
      plan: "lite",
      time: 48,
      availabilityHours: "10:00-18:00",
      availabilityDays: "Mon-Fri",
      requestsNum: 4,
      price: priceLite * 0.9 * 6,
      postpaidPrice: null,
      installment: null,
      discountPrice: priceLite * 0.9,
      originalPrice: priceLite,
    },
    {
      plan: "basic",
      time: 24,
      availabilityHours: "10:00-18:00",
      availabilityDays: "Mon-Fri",
      requestsNum: 10,
      price: priceNormal * 0.9 * 6,
      postpaidPrice: null,
      installment: null,
      discountPrice: priceNormal * 0.9,
      originalPrice: priceNormal,
    },
    {
      plan: "premium",
      time: 4,
      availabilityHours: "10:00-22:00",
      availabilityDays: "Mon-Sun",
      requestsNum: "unlimited",
      price: pricePremium * 0.9 * 6,
      postpaidPrice: null,
      installment: null,
      discountPrice: pricePremium * 0.9,
      originalPrice: pricePremium,
    },
  ]

  const annually = [
    {
      plan: "lite",
      time: 48,
      availabilityHours: "10:00-18:00",
      availabilityDays: "Mon-Fri",
      requestsNum: 4,
      price: priceLite * 0.85 * 12,
      postpaidPrice: null,
      installment: null,
      discountPrice: priceLite * 0.85,
      originalPrice: priceLite,
    },
    {
      plan: "basic",
      time: 24,
      availabilityHours: "10:00-18:00",
      availabilityDays: "Mon-Fri",
      requestsNum: 10,
      price: priceNormal * 0.85 * 12,
      postpaidPrice: null,
      installment: null,
      discountPrice: priceNormal * 0.85,
      originalPrice: priceNormal,
    },
    {
      plan: "premium",
      time: 4,
      availabilityHours: "10:00-22:00",
      availabilityDays: "Mon-Sun",
      requestsNum: "unlimited",
      price: pricePremium * 0.85 * 12,
      postpaidPrice: null,
      installment: null,
      discountPrice: pricePremium * 0.85,
      originalPrice: pricePremium,
    },
  ]

  const paymentMethods = {
    monthly: [...monthly],
    quarterly: [...quarterly],
    semiAnnually: [...semiAnnually],
    annually: [...annually],
  }

  return (
    <Content className="section reveal fade-bottom">
      <Title className="title" level={3}>
        Software support
      </Title>

      <Text className="section-description">
        I offer expert assistance, troubleshooting, and guidance to resolve any
        software-related issues you encounter. With a customer-focused approach,
        we're committed to delivering superior support and enhancing your
        overall software experience.
      </Text>

      <RadioGroup handleChange={handleRadioChange} value={paymentMethod} />

      <div className="card-container">
        {paymentMethods?.[paymentMethod]?.map(
          ({
            plan,
            time,
            availabilityHours,
            availabilityDays,
            requestsNum,
            price,
            postpaidPrice,
            installment,
            discountPrice,
            originalPrice,
          }) => (
            <Card className="gradient-card">
              <Title
                className="card-title"
                level={4}
                style={{ textTransform: "uppercase" }}
              >
                {plan}
              </Title>
              ▹{" "}
              <Text className="white" strong>
                Response time: {time} hours
              </Text>
              <br />▹{" "}
              <Text className="white" strong>
                Availability hours: {availabilityHours}
              </Text>
              <br />▹{" "}
              <Text className="white" strong>
                Availability days: {availabilityDays}
              </Text>
              <br />▹{" "}
              <Text className="white" strong>
                Number of requests included: {requestsNum}
              </Text>
              <br />
              <Title
                style={{ marginBottom: isDiscount && 0 }}
                className="total-price"
                level={4}
              >
                {price.toFixed(2)}€
              </Title>
              {discountPrice && (
                <div className="flex">
                  <Title className="secondary-text" delete level={5}>
                    {originalPrice.toFixed(2)}
                  </Title>
                  <Title className="secondary-text" level={5}>
                    &nbsp;{discountPrice.toFixed(2)}€/month
                  </Title>
                </div>
              )}
              {(postpaidPrice || installment) && (
                <Fragment>
                  <Divider className="divider" />
                  {postpaidPrice && (
                    <Text className="white" strong>
                      ▸ Postpaid price after 45 days: {postpaidPrice.toFixed(2)}
                      €
                    </Text>
                  )}
                  <br />
                  {installment && (
                    <Text className="white" strong>
                      ▸ Installment for three months: {installment.toFixed(2)}€
                      / mo
                    </Text>
                  )}
                  <br />
                </Fragment>
              )}
            </Card>
          )
        )}
      </div>
    </Content>
  )
}

export default SoftwareSupport
