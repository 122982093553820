import "../../App.css"

import { Card, Divider, Layout, Typography } from "antd"
import { Fragment, useState } from "react"

import { RadioGroup } from "../../components"
import { windowScroll } from "../../helpers"

const { Title, Text } = Typography
const { Content } = Layout

const SoftwareDevelopment = () => {
  const pricePerHour = 28.4
  const monthlyPrice = pricePerHour * 160

  const techLeadPrice = {
    monthly: {
      projects: 1,
      chatSupport: 11,
      shortVideoConsultations: 11,
      longVideoConsultations: 28,
      codeReview: 110,
      price: monthlyPrice,
      postpaidPrice: monthlyPrice * 1.1,
      installment: monthlyPrice * 1.2,
      discountPrice: null,
    },
    quarterly: {
      projects: 1,
      chatSupport: 11,
      shortVideoConsultations: 11,
      longVideoConsultations: 28,
      codeReview: 110,
      price: monthlyPrice * 0.95 * 3,
      postpaidPrice: null,
      installment: null,
      discountPrice: monthlyPrice * 0.95,
    },
    semiAnnually: {
      projects: 1,
      chatSupport: 11,
      shortVideoConsultations: 11,
      longVideoConsultations: 28,
      codeReview: 110,
      price: monthlyPrice * 0.9 * 6,
      postpaidPrice: null,
      installment: null,
      discountPrice: monthlyPrice * 0.9,
    },
    annually: {
      projects: 1,
      chatSupport: 11,
      shortVideoConsultations: 11,
      longVideoConsultations: 28,
      codeReview: 110,
      price: monthlyPrice * 0.85 * 12,
      postpaidPrice: null,
      installment: null,
      discountPrice: monthlyPrice * 0.85,
    },
  }

  // Radio buttons
  const [paymentMethod, setPaymentMethod] = useState("monthly")

  const isPriceDetailedMethods =
    techLeadPrice?.[paymentMethod]?.postpaidPrice ||
    techLeadPrice?.[paymentMethod]?.installment
  const isDiscount = techLeadPrice?.[paymentMethod]?.discountPrice

  const handleRadioChange = (e) => {
    setPaymentMethod(e.target.value) // Update the selected value when a radio button is clicked
  }

  // Window scroll
  window.addEventListener("scroll", windowScroll)
  windowScroll()

  return (
    <Content className="section reveal fade-bottom">
      <Title className="title" level={3}>
        Software development
      </Title>

      <Text className="section-description">
        With meticulous oversight, I ensure that every project reflects the
        pinnacle of technical excellence, resulting in robust, user-centric
        software products
      </Text>

      <RadioGroup handleChange={handleRadioChange} value={paymentMethod} />

      <div className="card-container">
        <Card className="gradient-card">
          <Title className="card-title" level={4}>
            MONTH
          </Title>
          ▹{" "}
          <Text className="white" strong>
            Projects: {techLeadPrice?.[paymentMethod]?.projects}
          </Text>
          <br />▹{" "}
          <Text className="white" strong>
            Meetings with team members:{" "}
            {techLeadPrice?.[paymentMethod]?.chatSupport} hours
          </Text>
          <br />▹{" "}
          <Text className="white" strong>
            Chat sessions:{" "}
            {techLeadPrice?.[paymentMethod]?.shortVideoConsultations} hours
          </Text>
          <br />▹{" "}
          <Text className="white" strong>
            Code review:{" "}
            {techLeadPrice?.[paymentMethod]?.longVideoConsultations} hours
          </Text>
          <br />▹{" "}
          <Text className="white" strong>
            Software development: {techLeadPrice?.[paymentMethod]?.codeReview}{" "}
            hours
          </Text>
          <br />
          <Title
            style={{ marginBottom: isDiscount && 0 }}
            className="total-price"
            level={4}
          >
            {techLeadPrice?.[paymentMethod]?.price.toFixed(2)}€
          </Title>
          {techLeadPrice?.[paymentMethod]?.discountPrice && (
            <div className="flex">
              <Title
                style={{
                  color: "rgba(255, 255, 255, 0.45)",
                  marginTop: 0,
                  fontWeight: 800,
                }}
                delete
                level={5}
              >
                {monthlyPrice.toFixed(2)}
              </Title>
              <Title
                style={{
                  color: "rgba(255, 255, 255, 0.45)",
                  marginTop: 0,
                  fontWeight: 800,
                }}
                level={5}
              >
                &nbsp;{techLeadPrice?.[paymentMethod]?.discountPrice.toFixed(2)}
                €/month
              </Title>
            </div>
          )}
          {isPriceDetailedMethods && (
            <Fragment>
              <Divider className="divider" />
              {techLeadPrice?.[paymentMethod]?.postpaidPrice && (
                <Text className="white" strong>
                  ▸ Postpaid price after 45 days:{" "}
                  {techLeadPrice?.[paymentMethod]?.postpaidPrice.toFixed(2)}€
                </Text>
              )}
              <br />
              {techLeadPrice?.[paymentMethod]?.installment && (
                <Text className="white" strong>
                  ▸ Installment for three months:{" "}
                  {techLeadPrice?.[paymentMethod]?.installment.toFixed(2)}€ / mo
                </Text>
              )}
              <br />
            </Fragment>
          )}
        </Card>
      </div>
    </Content>
  )
}

export default SoftwareDevelopment
